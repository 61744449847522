import React from 'react';
import PropTypes from 'prop-types';
import styled, { ThemeProvider } from 'styled-components';
import mediaQueries, { breakpoints } from '@utils/media-queries';
import {
  CarouselsContainer,
  CommonScripts,
  GridStairs,
  Head,
  Header,
  Reviews,
} from '@components';
import gridConfig, {
  headerStairs, headerStairsMobile,
} from '@utils/grid';
import { parseLandingContent } from '@utils/helpers';
import { colors } from '@utils/vars';
import useResizeObserver from 'use-resize-observer/polyfilled';
import BottomBar from '../components/BottomBar/BottomBar';

const {
  small,
  large,
  xlarge,
} = mediaQueries;

const {
  markerYellow: markerYellowColor,
} = colors;

const Wrapper = styled.section`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  font-size: 14px;

  @media ${xlarge} {
    font-size: 13px;
  }

  @media ${large} {
    font-size: 12px;
  }

  @media ${small} {
    overflow: scroll;
  }

  small {
    font-size: smaller;
  }

  .marker {
    background: ${markerYellowColor};
  }
`;
const Landing = ({
  pageContext: {
    content,
  },
}) => {
  const {
    ref, width: windowWidth = 0,
  } = useResizeObserver();
  const {
    header_image: headerImage,
    footer_image: footerImage,
    headline: title,
    description: subtitle,
    header_title: headerTitle,
    reviews,
    seo: meta,
    cta_enable: isChatEnabled,
  } = content;
  const {
    chat_interaction_id: driftId,
    cta_text: buttonLabel,
    carousels,
    slug,
    clutch,
  } = parseLandingContent(content);

  const paddingTop = Math.max(...headerStairs);
  const paddingTopMobile = Math.max(...headerStairsMobile);

  return (
    <Wrapper ref={ref}>
      {meta && (
        <Head
          meta={meta}
          title={meta.title}
        />
      )}
      <CommonScripts />
      <ThemeProvider theme={{ awesomegrid: gridConfig }}>
        <Header
          image={headerImage}
          title={title}
          subtitle={subtitle}
          headerTitle={headerTitle}
          buttonLabel={buttonLabel}
          driftId={driftId}
          isChatEnabled={isChatEnabled}
        />
        <GridStairs offsets={windowWidth > breakpoints.small ? headerStairs : headerStairsMobile} />
        <CarouselsContainer
          carousels={carousels}
          paddingTop={windowWidth > breakpoints.small ? paddingTop : paddingTopMobile}
          slug={slug}
          windowWidth={windowWidth}
        />
        <Reviews
          reviews={reviews}
          image={footerImage}
          windowWidth={windowWidth}
        />
        <BottomBar clutch={clutch} />
      </ThemeProvider>
    </Wrapper>
  );
};

Landing.propTypes = {
  pageContext: PropTypes.shape({
    content: PropTypes.object,
  }).isRequired,
};

export default Landing;
